import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SignUp from './components/signup/Signup';
import Login from './components/login/Login';
import Header from './components/header/Header';
import Profile from './components/profile/Profile';
import Settings from './components/settings/Settings';
import UserDashboard from './components/userDashboard/UserDashboard';
import TeamDashboard from './components/teamDashboard/TeamDashboard';
import { Route, Switch } from 'react-router-dom';
import { Link } from '@material-ui/core';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import StartCall from './components/call/StartCall';
import Survey from './components/survey/Survey';
import NotFound from './components/notFound/NotFound';
import { useIdleTimer } from 'react-idle-timer';
import PrivateRoute from './components/common/PrivateRoute';
import { ConfirmInvite } from './components/call/ConfirmInvite';
import { useUser } from './context/userContext';
import Session from './components/call/Session';
import Team from './components/team/Team';
import { mutation } from './graphql/client';
import { UPDATE_USER_STATUS } from './graphql/mutations';
import ResetPassword from './components/resetPassword/ResetPassword';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.dialin.co/">
        Dialin
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function App(): React.ReactElement {
  const { user } = useUser();

  const handleOnIdle = async () => {
    try {
      if (user.user) {
        console.log('User is idle. Last active', getLastActiveTime());
        await mutation(UPDATE_USER_STATUS, {
          status: 'AWAY',
        });
      }
    } catch (e) {
      console.log('failed to update user status', e);
    }
  };

  const handleOnActive = async () => {
    try {
      if (user.user) {
        await mutation(UPDATE_USER_STATUS, { status: 'ONLINE' });
      }
    } catch (e) {
      console.log('failed to update user status', e);
    }
  };

  useEffect(() => {
    if (user.user) {
      handleOnActive();
    }
    // eslint-disable-next-line
  }, [user.user]);

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onActive: handleOnActive,
    onAction: handleOnActive,
    onIdle: handleOnIdle,
    throttle: 1000 * 60,
  });

  return (
    <Container maxWidth="xl">
      <Header>
        <Container>
          <Box my={8}>
            {user.userAttributes && <ConfirmInvite userAttributes={user.userAttributes} />}
            <Switch>
              <PrivateRoute exact path={['/home', '/']}>
                <UserDashboard />
              </PrivateRoute>
              <Route exact path="/signup">
                <SignUp />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <PrivateRoute exact path="/admin">
                <TeamDashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/profile">
                <Profile />
              </PrivateRoute>
              <PrivateRoute exact path="/call">
                <StartCall />
              </PrivateRoute>
              <PrivateRoute exact path="/settings">
                <Settings />
              </PrivateRoute>
              <PrivateRoute exact path="/team">
                <Team />
              </PrivateRoute>
              <PrivateRoute exact path="/session/:id">
                <Session />
              </PrivateRoute>
              <PrivateRoute exact path="/survey/:id">
                <Survey />
              </PrivateRoute>
              <Route exact path="/reset">
                <ForgotPassword />
              </Route>
              <Route exact path="/reset_password">
                <ResetPassword />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </Box>
        </Container>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Header>
    </Container>
  );
}

export default App;
