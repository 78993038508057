import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import { Box, Card, CardContent, CardHeader, Divider, Typography, colors, makeStyles } from '@material-ui/core';
import { User } from '../../common/types';
import { useQuery } from '../../graphql/client';
import { LIST_USERS } from '../../graphql/queries';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

export interface UsersQueryResult {
  getUsers: UsersPage;
}

export interface UsersPage {
  users: User[];
  nextToken: string;
}

const getGraphData = (stats: Array<number>) => {
  return {
    datasets: [
      {
        data: stats,
        backgroundColor: [colors.indigo[500], colors.red[600], colors.orange[600]],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: ['0.0 - 2.9', '3.0 - 3.9', '4.0 - 5.0'],
  };
};

export default function TeamPerformance(): React.ReactElement {
  const classes = useStyles();
  const [stats, setStats] = useState<Array<number>>([0, 0, 0]);

  const { data } = useQuery<UsersQueryResult>(LIST_USERS);

  useEffect(() => {
    if (data && data.getUsers && data.getUsers.users) {
      let low = 0;
      let med = 0;
      let high = 0;

      data.getUsers.users.forEach((user) => {
        if (user.averageScore > 3.9) {
          high += 1;
        } else if (user.averageScore < 4 && user.averageScore > 2.9) {
          med += 1;
        } else if (user.averageScore < 3) {
          low += 1;
        }
      });

      setStats([low, med, high]);
    }
  }, [data]);

  const options = {
    animation: false,
    cutoutPercentage: 60,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  const devices = [
    {
      title: '0.0 - 2.9',
      value: '0.0 - 2.9',
      color: colors.indigo[500],
    },
    {
      title: '3.0 - 3.9',
      value: '3.0 - 3.9',
      color: colors.red[600],
    },
    {
      title: '4.0 - 5.0',
      value: '4.0 - 5.0',
      color: colors.orange[600],
    },
  ];

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title="Team Performance" />
      <Divider />
      <CardContent>
        <Box height={300} position="relative">
          <Doughnut data={getGraphData(stats)} options={options} />
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          {devices.map(({ color, title, value }) => (
            <Box key={title} p={1} textAlign="center">
              <Typography style={{ color }} variant="h6">
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
