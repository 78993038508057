import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { User } from '../../common/types';
import { useQuery } from '../../graphql/client';
import { GET_MY_PROFILE } from '../../graphql/queries';

export interface QueryResult {
  getMyProfile: User;
}

const MyAverageScore = (): React.ReactElement => {
  const [averageScore, setAverageScore] = useState<string>('0.0');
  const { data } = useQuery<QueryResult>(GET_MY_PROFILE);

  useEffect(() => {
    if (data && data.getMyProfile && data.getMyProfile.averageScore) {
      setAverageScore(data.getMyProfile.averageScore.toFixed(1).toString());
    }
  }, [data]);

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {averageScore}
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        Avg Score
      </Typography>
    </>
  );
};

export default MyAverageScore;
