import { Avatar } from '@material-ui/core';
import React from 'react';

export interface AvatarProps {
  firstName: string;
  lastName: string;
  imageUrl?: string;
}

export default function ProfileAvatar(props: AvatarProps): React.ReactElement {
  return <Avatar alt={`${props.firstName} ${props.lastName}`} src={props.imageUrl} />;
}
