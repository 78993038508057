import { Card, CardContent, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { mutation } from '../../graphql/client';
import { UPDATE_SELLER_SESSION } from '../../graphql/mutations';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 700,
  },
}));

export interface NotesProps {
  initialNotes: string;
  sessionId: string;
}

export default function SellerNotes({ initialNotes, sessionId }: NotesProps): React.ReactElement {
  const classes = useStyles();
  const [notes, setNotes] = useState(!!initialNotes ? initialNotes : '');

  const updateSellerNotes = async () => {
    await mutation(UPDATE_SELLER_SESSION, { sessionId: sessionId, sellerNotes: notes });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (initialNotes !== notes) {
        updateSellerNotes();
      }
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [notes]);

  return (
    <Card className={classes.card} elevation={2}>
      <CardContent>
        <Typography variant={'h5'}>Notes</Typography>
        <TextField
          className={classes.card}
          multiline
          rows={16}
          placeholder={'Write any important information, dates, contacts, ideas you discover during your call!'}
          onChange={(event) => setNotes(event.target.value)}
          value={notes}
        ></TextField>
      </CardContent>
    </Card>
  );
}
