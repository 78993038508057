import { Box, Divider, IconButton, Menu } from '@material-ui/core';
import React, { useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AudioOption from './AudioOption';
import { AudioDevice } from '../call/Room';

export interface DeviceSelectedHandler {
  (device: AudioDevice): void;
}

export interface AudioOptionsProps {
  inputDevices: Array<AudioDevice>;
  outputDevices: Array<AudioDevice>;
  inputDeviceSelectedHandler: DeviceSelectedHandler;
  outputDeviceSelectedHandler: DeviceSelectedHandler;
}

export default function AudioOptions(props: AudioOptionsProps): React.ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleOptionsClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <Box textAlign="left" fontWeight="fontWeightBold" fontSize={16} m={0.8}>
          Select a Microphone
        </Box>

        {/* microphones */}
        {props.inputDevices.map((d) => (
          <AudioOption
            deviceName={d.label}
            isSelected={d.isActive}
            handleClick={() => props.inputDeviceSelectedHandler(d)}
            key={d.deviceId}
          />
        ))}

        <Divider />

        <Box textAlign="left" fontWeight="fontWeightBold" fontSize={16} m={0.8}>
          Select a Speaker
        </Box>

        {/* speakers */}
        {props.outputDevices.map((d) => (
          <AudioOption
            deviceName={d.label}
            isSelected={d.isActive}
            handleClick={() => props.outputDeviceSelectedHandler(d)}
            key={d.deviceId}
          />
        ))}
      </Menu>
    </>
  );
}
