import React, { useState, useEffect, ReactNode } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, CircularProgressProps } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    playIcon: {
      height: 38,
      width: 38,
    },
  }),
);

export interface MediaPlayerProps {
  url: string;
  duration: number;
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number } & { children: ReactNode }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function SmallMediaPlayer(props: MediaPlayerProps): React.ReactElement {
  const classes = useStyles();
  const [audio] = useState(new Audio(props.url));
  const [play, setPlay] = useState(false);
  const [progress, setProgress] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [elapsedTime, setElapsedTime] = useState(0);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (props.url && props.duration) {
      setDisabled(false);
      audio.addEventListener('ended', () => {
        setPlay(false);
        setProgress(0);
      });
    }

    const timer = setInterval(() => {
      if (play) {
        setElapsedTime((prevElapsedTime) => {
          const elapsedTime = prevElapsedTime + 1;

          setProgress(() => {
            if (elapsedTime >= props.duration) {
              return 100;
            } else {
              return Math.ceil((elapsedTime / props.duration) * 100);
            }
          });

          return elapsedTime;
        });
      }
    }, 1000);

    return () => {
      audio.removeEventListener('ended', () => setPlay(false));
      clearInterval(timer);
    };
  }, [audio, play, props.duration, props.url]);

  const handlePlay = () => {
    !play ? audio.play() : audio.pause();
    setPlay(!play);
  };
  return (
    <CircularProgressWithLabel value={progress} thickness={6} size={50}>
      <IconButton aria-label="play/pause" onClick={handlePlay} disabled={disabled}>
        {!play ? <PlayArrowIcon className={classes.playIcon} /> : <PauseIcon className={classes.playIcon} />}
      </IconButton>
    </CircularProgressWithLabel>
  );
}
