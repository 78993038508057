import React from 'react';

import MuteButton from './MuteButton';
import CallButton from './CallButton';
import AudioOptions from './AudioOptions';
import { BoolParamVoidFunction, NoParamsVoidFunction } from '../../common/types';
import { AudioDevice, AudioDeviceParamFunction, DeviceState } from '../call/Room';

export interface CallAudioControlsProps {
  deviceState: DeviceState;
  isMuted: boolean;
  handleMute: BoolParamVoidFunction;
  handleCall: NoParamsVoidFunction;
  handleSelectMic: AudioDeviceParamFunction;
  handleSelectSpeaker: AudioDeviceParamFunction;
  inputDevices: Array<AudioDevice>;
  outputDevices: Array<AudioDevice>;
}

export default function CallAudioControls(props: CallAudioControlsProps): React.ReactElement {
  return (
    <React.Fragment>
      {props.deviceState === DeviceState.ON_CALL ? <MuteButton handleClick={props.handleMute} /> : null}
      <CallButton handleClick={props.handleCall} />
      <AudioOptions
        inputDeviceSelectedHandler={props.handleSelectMic}
        outputDeviceSelectedHandler={props.handleSelectSpeaker}
        inputDevices={props.inputDevices}
        outputDevices={props.outputDevices}
      />
    </React.Fragment>
  );
}
