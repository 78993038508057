import React from 'react';
import { Grid } from '@material-ui/core';
import RecordingsTable from './RecordingsTable';
import MyAverageScore from '../common/MyAverageScore';

export default function UserDashboard(): React.ReactElement {
  return (
    <Grid container spacing={2}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item>
          <MyAverageScore />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <RecordingsTable />
      </Grid>
    </Grid>
  );
}
