import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useSnackbar } from 'notistack';

const SnackbarAction = (key: string): React.ReactElement => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Fragment>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </Fragment>
  );
};

export default SnackbarAction;
