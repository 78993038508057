/**
 * Function to convert seconds to a displayable string
 * @param timeInSeconds time in seconds to be formatted
 * @returns A string in the format mm:ss
 */
export const secondsToString = (timeInSeconds: number): string => {
  const minutes = Math.floor(timeInSeconds / 60).toString(10);
  let seconds = (timeInSeconds % 60).toString(10);
  seconds = seconds.length === 1 ? seconds.padStart(2, '0') : seconds;

  return `${minutes}:${seconds}`;
};

export const formatDate = (timeInSeconds: number): string => new Date(timeInSeconds * 1000).toLocaleString();
