import React from 'react';
import Emoji from 'react-emoji-render';
import { Typography } from '@material-ui/core';

export interface LeadEmojiProps {
  type: string;
}

export default function LeadEmoji(props: LeadEmojiProps): React.ReactElement {
  switch (props.type) {
    case 'HOT':
      return (
        <Typography variant="h4">
          <Emoji text=":grinning_face_with_big_eyes:" />
        </Typography>
      );

    case 'WARM':
      return (
        <Typography variant="h4">
          <Emoji text=":neutral_face:" />
        </Typography>
      );

    case 'COLD':
      return (
        <Typography variant="h4">
          <Emoji text=":face_without_mouth:" />
        </Typography>
      );

    default:
      return <Emoji text=":question_mark:" />;
  }
}
