import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NEW_SESSION_SUBSCRIPTION } from '../../graphql/subscriptions';
import { Dictionary } from '../../context/userContext';
import { RESPOND_TO_INVITE } from '../../graphql/mutations';
import { useHistory } from 'react-router-dom';
import { mutation, useSubscription } from '../../graphql/client';

export interface SessionInvite {
  createdSession: CreateSessionPayload;
}

export interface User {
  email: string;
  givenName: string;
  familyName: string;
}

export interface Session {
  seller: User;
  objective: string;
}

export interface CreateSessionPayload {
  recordId: string;
  record: Session;
}

export interface ConfirmInviteProps {
  userAttributes: Dictionary<string>;
}

export function ConfirmInvite({ userAttributes }: ConfirmInviteProps): React.ReactElement {
  const history = useHistory();

  const [item] = useSubscription<CreateSessionPayload>({
    config: {
      query: NEW_SESSION_SUBSCRIPTION,
      variables: { email: userAttributes.email },
      key: 'createdSession',
    },
  });

  useEffect(() => {
    if (item) {
      setCreatedSession(item);
      setOpen(true);
    }
  }, [item]);

  const [open, setOpen] = useState(false);
  const [createdSession, setCreatedSession] = useState<CreateSessionPayload | null>(null);

  const handleCancel = async () => {
    await mutation(RESPOND_TO_INVITE, { sellerEmail: createdSession?.record.seller.email, accepted: false });
    setOpen(false);
  };

  const handleOk = async () => {
    await mutation(RESPOND_TO_INVITE, { sellerEmail: createdSession?.record.seller.email, accepted: true });
    setOpen(false);
    history.push(`/session/${createdSession?.recordId}`);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">Session Invite</DialogTitle>
      <DialogContent dividers>
        <Typography>{`${createdSession?.record.seller.givenName} ${createdSession?.record.seller.familyName} invited you to a practice session`}</Typography>
        <Typography>{`Session Objective: ${createdSession?.record.objective}`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          No
        </Button>
        <Button onClick={handleOk} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
