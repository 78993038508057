import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useHistory } from 'react-router-dom';
import { LIST_USERS } from '../../graphql/queries';
import LeadEmoji from '../common/LeadEmoji';
import { CREATE_NEW_SESSION } from '../../graphql/mutations';
import { useUser } from '../../context/userContext';
import { SESSION_INVITE_RESPONSE_SUBSCRIPTION } from '../../graphql/subscriptions';
import { User } from '../../common/types';
import { mutation, useQuery, useSubscription } from '../../graphql/client';

interface QueryResult {
  getUsers: UsersPage;
}

interface UsersPage {
  users: User[];
  nextToken: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  grid: {
    paddingBottom: theme.spacing(8),
  },
  emoji: {
    fontSize: 'large',
  },
  typography: {
    paddingBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface CallType {
  value: string;
  type: string;
  description: string;
  emoji: React.ReactElement;
}

interface DependData {
  [key: string]: CallType;
}

export interface InviteResponse {
  accepted: boolean;
}

export interface SessionInviteResponse {
  respondedToSessionInvite: InviteResponse;
}

export interface CreateSessionPayload {
  recordId: string;
}

export interface CreateSessionResponse {
  createSession: CreateSessionPayload;
}

const callTypes: DependData = {
  COLD: {
    value: 'COLD',
    type: 'Cold Lead',
    description:
      '(This is your first conversation with this customer/contact. They are unaware of you and not expecting a call)',
    emoji: <LeadEmoji type="COLD" />,
  },
  // WARM: {
  //   value: 'WARM',
  //   type: 'Warm Lead',
  //   description:
  //     '(You have had a handful of conversations with the customer/contact. Customer is semi-aware of your offerings)',
  //   emoji: <LeadEmoji type="WARM" />,
  // },
  // HOT: {
  //   value: 'HOT',
  //   type: 'Hot Lead',
  //   description:
  //     '(This is a scheduled call with the customer/contact. You have discussed specific solutions but no decision has been made)',
  //   emoji: <LeadEmoji type="HOT" />,
  // },
};

export default function StartCall(): React.ReactElement {
  const classes = useStyles();
  const { user } = useUser();
  const history = useHistory();

  const { loading, data, refetch } = useQuery<QueryResult>(LIST_USERS);
  const [buyer, setBuyer] = useState<User>();
  const [purpose, setPurpose] = useState<string>('');
  const [callType, setCallType] = useState<string>('');
  const [session, setSession] = useState<CreateSessionResponse | undefined>(undefined);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);
    return () => clearInterval(interval);
  }, [refetch]);

  const handleSelectBuyer = (event: React.MouseEvent<HTMLElement, MouseEvent>, user: User) => {
    if (user) {
      setBuyer(user);
    }
  };

  const handleSelectPurpose = (event: React.MouseEvent<HTMLElement, MouseEvent>, purpose: string) => {
    if (purpose) {
      setPurpose(purpose);
    }
  };

  const handleCallTypeChange = (event: React.MouseEvent<HTMLElement>, newCallType: string) => {
    if (newCallType) {
      setCallType(newCallType);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleContinue = async () => {
    setOpen(!open);
    // const response = await createNewSession({
    //   variables: {
    //     buyerEmail: buyer?.email,
    //     sellerEmail: user.userAttributes?.email,
    //     leadType: callType,
    //     objective: purpose,
    //   },
    // });

    const response = await mutation<CreateSessionResponse>(CREATE_NEW_SESSION, {
      buyerEmail: buyer?.email,
      sellerEmail: user.userAttributes?.email,
      leadType: callType,
      objective: purpose,
    });

    setSession(response);
  };

  const handleInviteResponse = (response: boolean) => {
    if (response) {
      if (session) {
        history.push(`/session/${session?.createSession.recordId}`);
      }
    } else {
      alert('user declined your invite');
    }
  };

  const [item] = useSubscription<InviteResponse>({
    config: {
      query: SESSION_INVITE_RESPONSE_SUBSCRIPTION,
      variables: { sellerEmail: user.userAttributes?.email },
      key: 'respondedToSessionInvite',
    },
  });

  useEffect(() => {
    if (item) {
      handleInviteResponse(item.accepted);
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [item]);

  return (
    <Card>
      <CardHeader title="Start Call" />
      <Divider />
      <CardContent>
        <Container component="main" maxWidth="xl">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5" className={classes.typography}>
              Invite Customer
            </Typography>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center" className={classes.grid}>
              {loading && <CircularProgress />}
              <ToggleButtonGroup value={buyer} exclusive onChange={handleSelectBuyer} aria-label="call type">
                {data && data.getUsers && data.getUsers.users ? (
                  data?.getUsers.users
                    .filter(
                      (t) =>
                        t.email !== user.userAttributes?.email &&
                        Math.floor(Date.now() / 1000) - t.updatedAt < 300 &&
                        t.status === 'ONLINE',
                    )
                    .map((teamMate) => {
                      return (
                        <ToggleButton
                          value={teamMate}
                          aria-label={`${teamMate.givenName} ${teamMate.familyName}`}
                          key={teamMate.email}
                        >
                          {`${teamMate.givenName} ${teamMate.familyName}`}
                        </ToggleButton>
                      );
                    })
                ) : (
                  <p>No online users</p>
                )}
              </ToggleButtonGroup>
            </Grid>
            <Typography component="h1" variant="h5" className={classes.typography}>
              What&apos;s the purpose of this call?
            </Typography>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center" className={classes.grid}>
              <ToggleButtonGroup value={purpose} exclusive onChange={handleSelectPurpose} aria-label="Call Purpose">
                {['Set Meeting', 'Discovery', 'Follow up', 'Other...'].map((p) => {
                  return (
                    <ToggleButton value={p} aria-label={p} key={p}>
                      {p}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Grid>
            <Typography component="h1" variant="h5" className={classes.typography}>
              What type of call is this?
            </Typography>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.grid}
              spacing={2}
            >
              <Grid item>
                <Typography variant="h6" display="block" gutterBottom>
                  {callType ? callTypes[callType].type : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" display="block" gutterBottom>
                  {callType ? callTypes[callType].description : ''}
                </Typography>
              </Grid>
              <Grid item>
                <ToggleButtonGroup value={callType} exclusive onChange={handleCallTypeChange} aria-label="call type">
                  {Object.values(callTypes).map((callType) => {
                    return (
                      <ToggleButton value={callType.value} aria-label={callType.type} key={callType.value}>
                        {callType.emoji}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              disabled={!buyer || !purpose || !callType}
              onClick={handleContinue}
            >
              Continue
            </Button>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                  <CircularProgress color="inherit" />
                </Grid>
                <Grid item>
                  <Typography>Inviting {`${buyer?.givenName} ${buyer?.familyName}`}...</Typography>
                </Grid>
              </Grid>
            </Backdrop>
          </div>
        </Container>
      </CardContent>
    </Card>
  );
}
