import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Observable from 'zen-observable';
import useDeepCompareEffect from 'use-deep-compare-effect';

type ConfigType<VariableType extends {}> = {
  query: any;
  key: string;
  variables?: VariableType;
};

export const useSubscription = <ItemType extends {}, VariablesType extends {} = {}>({
  config,
  itemData,
}: {
  config?: ConfigType<VariablesType>;
  itemData?: ItemType;
} = {}): (ItemType | undefined)[] => {
  const [item, update] = React.useState<ItemType | undefined>(itemData);

  React.useEffect(() => {
    let unsubscribe;
    if (config) {
      const { query, key, variables } = config;
      const subscription = API.graphql(graphqlOperation(query, variables));
      if (subscription instanceof Observable) {
        const sub = subscription.subscribe({
          next: (payload) => {
            try {
              const {
                value: {
                  data: { [key]: item },
                },
              }: { value: { data: { [key: string]: ItemType } } } = payload;

              update(item);
            } catch (error) {
              console.error(`${error.message} - Check the key property: the current value is ${key}`);
            }
          },
        });
        unsubscribe = () => {
          sub.unsubscribe();
        };
      }
    }
    return unsubscribe;
    // eslint-disable-next-line
  }, [JSON.stringify(config)]);

  return [item];
};

type UseQueryType<ResultType, VariablesType> = {
  loading: boolean;
  error: any;
  data: ResultType;
  refetch: (refetchVariables?: VariablesType) => void;
};

export const useQuery = <ResultType extends {}, VariablesType extends {} = {}>(
  query: any,
  variables?: VariablesType,
): UseQueryType<ResultType, VariablesType> => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [data, setData] = React.useState({} as ResultType);

  const fetchQuery = async (query: any, variables?: VariablesType) => {
    try {
      setLoading(true);
      const data = await gqlOp<ResultType, VariablesType>(query, variables);
      setData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const refetch = (refetchVariables?: VariablesType) => {
    let newVars = variables;

    if (refetchVariables && Object.keys(refetchVariables).length) {
      newVars = { ...variables, ...refetchVariables };
    }

    fetchQuery(query, newVars);
  };

  useDeepCompareEffect(() => {
    fetchQuery(query, variables);
  }, [query, variables]);

  return {
    loading,
    data,
    error,
    refetch,
  };
};

export const gqlOp = async <ResultType extends {}, VariablesType extends {} = {}>(
  query: any,
  variables?: VariablesType,
): Promise<ResultType> => {
  const { data } = (await API.graphql(graphqlOperation(query, variables))) as {
    data: ResultType;
  };
  return data;
};

export const mutation = async <ResultType extends {}, VariablesType extends {} = {}>(
  query: any,
  variables?: VariablesType,
): Promise<ResultType> => gqlOp<ResultType, VariablesType>(query, variables);
