import React from 'react';
import Rating from '@material-ui/lab/Rating';
import { NumberParamsVoidFunction } from '../../common/types';
import { Question } from './Survey';

export interface QuestionRatingProps {
  handleSelectRating: NumberParamsVoidFunction;
  question: Question;
  index: number;
}

export default function QuestionRating(props: QuestionRatingProps): React.ReactElement {
  return (
    <Rating
      name="half-rating"
      precision={0.5}
      defaultValue={0.0}
      max={5}
      size={'large'}
      value={props.question.score}
      onChange={(event, newValue) => {
        props.handleSelectRating(newValue || 0, props.index);
      }}
    />
  );
}
