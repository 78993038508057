import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/userContext';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { theme } from './theme';
import Amplify, { Auth } from 'aws-amplify';

const appConfig = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE,
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
  }),
};

Amplify.configure({
  API: appConfig,
});

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </ThemeProvider>
      </UserProvider>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
