import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function NotFound(): React.ReactElement {
  return (
    <Grid container direction="column" alignContent="center" alignItems="center">
      <Typography variant="h3">Page not found :(</Typography>
      <Typography variant="body1">
        Maybe the page you are looking for has been removed, or you typed in the wrong URL
      </Typography>
      <Button component={Link} to="/home" variant="outlined">
        GO TO HOMEPAGE
      </Button>
    </Grid>
  );
}
