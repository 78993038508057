import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

export interface handleClick {
  (): void;
}

export interface AudioOptionProps {
  handleClick: handleClick;
  isSelected: boolean;
  deviceName: string;
}

export default function AudioOption(props: AudioOptionProps): React.ReactElement {
  return (
    <MenuItem onClick={props.handleClick}>
      {props.isSelected ? (
        <ListItemIcon>
          <CheckIcon fontSize="small" />
        </ListItemIcon>
      ) : (
        <ListItemIcon></ListItemIcon>
      )}
      <Typography variant="inherit" noWrap>
        {props.deviceName}
      </Typography>
    </MenuItem>
  );
}
