import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1B31EA',
    },
    secondary: {
      main: '#F91B21',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f2f4f7',
    },
    info: {
      main: '#B2BBFF',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
});

export { theme };
