import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import { BoolParamVoidFunction } from '../../common/types';

export interface MuteButtonProps {
  handleClick: BoolParamVoidFunction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

export default function MuteButton(props: MuteButtonProps): React.ReactElement {
  const classes = useStyles();
  const [isMuted, setIsMuted] = useState(false);
  const handleClick = () => {
    setIsMuted(!isMuted);
    props.handleClick(!isMuted);
  };
  return (
    <Button
      variant="contained"
      color="default"
      className={classes.margin}
      startIcon={isMuted ? <MicOffIcon /> : <MicIcon />}
      onClick={handleClick}
    >
      {isMuted ? 'Unmute' : 'Mute'}
    </Button>
  );
}
