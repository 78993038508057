import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

export default function SignUpConfirmation(): React.ReactElement {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
      <Grid item>
        <EmailIcon style={{ fontSize: 60 }} color="primary" />
      </Grid>
      <Grid item>
        <Typography variant="h5" gutterBottom align="center">
          Great! You&apos;ve successfully signed up for Dialin.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom align="center">
          We&apos;ve sent you a link to confirm your email address. Please check your inbox.
        </Typography>
      </Grid>
    </Grid>
  );
}
