import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, TextField } from '@material-ui/core';
import { useUser } from '../../context/userContext';

const AccountDetails = (): React.ReactElement => {
  const { userAttributes } = useUser().user;

  const [values, setValues] = useState({
    firstName: userAttributes ? userAttributes['given_name'] : 'no name',
    lastName: userAttributes ? userAttributes['family_name'] : 'no lastname',
    email: userAttributes ? userAttributes['email'] : 'no email',
    jobTitle: userAttributes ? userAttributes['custom:jobTitle'] : 'no job title',
    company: userAttributes ? userAttributes['custom:companyName'] : 'no company',
    team: userAttributes ? userAttributes['custom:teamName'] : 'no team',
  });

  const handleChange = (event: { target: { name: string; value: unknown } }) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Card>
      <form autoComplete="off" noValidate>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Work Email"
                margin="dense"
                name="email"
                onChange={handleChange}
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Job Title"
                margin="dense"
                name="jobTitle"
                onChange={handleChange}
                value={values.jobTitle}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Company"
                margin="dense"
                name="company"
                onChange={handleChange}
                required
                value={values.company}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Team"
                margin="dense"
                name="team"
                onChange={handleChange}
                required
                value={values.team}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="contained" disabled>
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
};

export default AccountDetails;
