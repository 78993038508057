import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { BuyerPersona, Persona } from './Persona';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { UPDATE_BUYER_SESSION } from '../../graphql/mutations';
import { mutation } from '../../graphql/client';

const personas: Array<Persona> = [
  {
    name: 'Avery Garcia',
    company: 'University of Texas',
    industry: 'Education',
    timeInPosition: '3 yrs',
    education: 'University of Southern California',
    location: 'Austin, Texas',
    jobTile: 'Teacher',
  },
  {
    name: 'Peyton Miller',
    company: 'N/A',
    industry: 'Education',
    timeInPosition: '1 yrs',
    education: 'N/A',
    location: 'Seattle, Washington',
    jobTile: 'Student',
  },
  {
    name: 'Blake Allen',
    company: 'Apple',
    industry: 'Computer Hardware',
    timeInPosition: '10 yrs',
    education: 'Georgia Institute of Technology',
    location: 'Atlanta, Georgia',
    jobTile: 'Manager',
  },
];

interface HandleClickPersona {
  (selectedPersona: Persona): void;
}

interface ChoosePersonaProperties {
  handlePersonaSelect: HandleClickPersona;
  sessionId: string;
}

export default function ChoosePersona(props: ChoosePersonaProperties): React.ReactElement {
  const [open, setOpen] = useState(true);
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [persona, setPersona] = useState<Persona | undefined>(undefined);

  const onPersonaSelect = (selectedPersona: Persona) => {
    setSelectDisabled(false);
    setPersona(selectedPersona);
  };

  const handleClose = async () => {
    if (persona) {
      await mutation(UPDATE_BUYER_SESSION, {
        sessionId: props.sessionId,
        buyerPersona: persona,
      });
      props.handlePersonaSelect(persona);
      setOpen(false);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Select Persona</DialogTitle>
      <DialogContent>
        <Grid item container direction="row" justify="center" alignItems="center" spacing={1}>
          {personas.map((persona) => (
            <BuyerPersona key={persona.name} persona={persona} onClickHandler={onPersonaSelect} />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={selectDisabled}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
