import React, { ElementType } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Button, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 3px 0 25px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

export interface NavItemProps {
  href: string;
  icon: ElementType;
  title: string;
}

const NavItem = (props: NavItemProps) => {
  const classes = useStyles();

  const Icon = props.icon;

  return (
    <ListItem className={clsx(classes.item)} disableGutters>
      <Button activeClassName={classes.active} className={classes.button} component={RouterLink} to={props.href}>
        <ListItemIcon>
          <Icon className={classes.icon} size="20" />
        </ListItemIcon>

        <ListItemText className={classes.title} primary={props.title} />
      </Button>
    </ListItem>
  );
};

export default NavItem;
