import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IconButton, Card, CardContent, CardHeader, Divider, TablePagination, TableFooter } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import { LIST_USER_SESSIONS } from '../../graphql/queries';
import { secondsToString, formatDate } from '../../common/utils';
import LeadEmoji from '../common/LeadEmoji';
import SmallMediaPlayer from '../common/SmallMediaPlayer';
import { User } from '../../common/types';
import { useQuery } from '../../graphql/client';

export interface Session {
  id: string;
  teamName: string;
  score: number;
  objective: string;
  leadType: string;
  buyer: User;
  recordingDuration: number;
  recordingUrl: string;
  createdAt: number;
}

export interface QueryResult {
  listUserSessions: SessionConnection;
}

export interface SessionConnection {
  items: Session[];
  nextToken: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTable(): React.ReactElement {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [index, setIndex] = React.useState<Map<number, string>>(new Map());
  const [count, setCount] = React.useState(0);
  const { loading, data, refetch } = useQuery<QueryResult>(LIST_USER_SESSIONS, {
    limit: rowsPerPage,
  });

  const handleRefresh = async () => {
    refetch();
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [rowsPerPage]);

  useEffect(() => {
    if (data && data.listUserSessions) {
      setCount(() => {
        let newCount = page * rowsPerPage + data?.listUserSessions.items.length;

        if (data.listUserSessions.nextToken) {
          newCount = newCount + 1;
        }

        return newCount;
      });
    }
  }, [data, rowsPerPage, page]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    const vars: { [key: string]: string } = {};

    if (newPage < page && newPage !== 0) {
      const token = index.get(newPage);
      if (token) {
        vars['nextToken'] = token;
      }
    } else if (newPage !== 0) {
      vars['nextToken'] = data?.listUserSessions.nextToken;

      setIndex((prevIndex) => {
        const newIndex = new Map(prevIndex);
        newIndex.set(newPage, data?.listUserSessions.nextToken);

        return newIndex;
      });
    }
    setPage(newPage);
    refetch(vars);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCount(0);
  };

  return (
    <Card>
      <CardHeader
        title="Recordings"
        action={
          <IconButton aria-label="settings" onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SCORE</TableCell>
                  <TableCell align="center">CALL PURPOSE</TableCell>
                  <TableCell align="center">LEAD TYPE</TableCell>
                  <TableCell align="center">CUSTOMER</TableCell>
                  <TableCell align="center">CALL DURATION</TableCell>
                  <TableCell align="center">DATE</TableCell>
                  <TableCell align="center">LISTEN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.listUserSessions ? (
                  data.listUserSessions.items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell align="center" component="th" scope="row">
                        {item.score ? item.score.toFixed(1) : 'No Score'}
                      </TableCell>
                      <TableCell align="center">{item.objective}</TableCell>
                      <TableCell align="center">
                        <LeadEmoji type={item.leadType} />
                      </TableCell>
                      <TableCell align="center">{`${item.buyer.givenName} ${item.buyer.familyName}`}</TableCell>
                      <TableCell align="center">{secondsToString(item.recordingDuration)}</TableCell>
                      <TableCell align="center">{formatDate(item.createdAt)}</TableCell>
                      <TableCell align="center">
                        <SmallMediaPlayer url={item.recordingUrl} duration={item.recordingDuration} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <p>No recordings yet</p>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 50]}
                    colSpan={9}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
}
