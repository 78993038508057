import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { CREATE_INVITE } from '../../graphql/mutations';
import { mutation } from '../../graphql/client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export interface InviteFormProps {
  open: boolean;
  handleClose: VoidFunction;
}

export default function InviteForm(props: InviteFormProps): React.ReactElement {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleClose = () => {
    setEmail('');
    props.handleClose();
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await mutation(CREATE_INVITE, { email: email });
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    props.handleClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Invite</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To invite a new member to your team, please enter your email address here. We will send them an email with
          instructions on how to sign up.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          disabled={loading}
          onChange={(event) => setEmail(event.target.value)}
          value={email}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <div className={classes.wrapper}>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Invite
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
