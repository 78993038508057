import gql from 'graphql-tag';

export const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($status: UserStatus!) {
    updateUserStatus(status: $status) {
      email
    }
  }
`;

export const CREATE_NEW_SESSION = gql`
  mutation createNewSession(
    $sellerEmail: AWSEmail!
    $buyerEmail: AWSEmail!
    $leadType: LeadType!
    $objective: String!
  ) {
    createSession(sellerEmail: $sellerEmail, buyerEmail: $buyerEmail, leadType: $leadType, objective: $objective) {
      recordId
      buyerEmail
      record {
        seller {
          email
          givenName
          familyName
        }
        objective
      }
    }
  }
`;

export const RESPOND_TO_INVITE = gql`
  mutation respondToInvite($sellerEmail: AWSEmail!, $accepted: Boolean!) {
    respondToSessionInvite(accepted: $accepted, sellerEmail: $sellerEmail) {
      sellerEmail
      accepted
    }
  }
`;

export const UPDATE_SELLER_SESSION = gql`
  mutation updateSellerSession($sessionId: ID!, $sellerNotes: String!) {
    updateSellerSession(input: { sellerNotes: $sellerNotes, sessionId: $sessionId }) {
      recordId
    }
  }
`;

export const UPDATE_BUYER_SESSION = gql`
  mutation updateBuyerSession($sessionId: ID!, $buyerPersona: PersonaInput!) {
    updateBuyerSession(input: { buyerPersona: $buyerPersona, sessionId: $sessionId }) {
      recordId
      record {
        name
        company
        industry
        timeInPosition
        education
        location
        jobTile
      }
    }
  }
`;

export const CREATE_SESSION_TOKEN = gql`
  mutation createSessionToken {
    createSessionToken {
      recordId
    }
  }
`;

export const UPDATE_SESSION_SURVEY = gql`
  mutation UpdateSessionSurvey($sessionId: ID!, $surveyDetails: SurveyDetailsInput!) {
    updateSessionSurvey(input: { sessionId: $sessionId, surveyDetails: $surveyDetails }) {
      recordId
    }
  }
`;

export const CREATE_INVITE = gql`
  mutation CreateInvite($email: String!) {
    invite(email: $email)
  }
`;
