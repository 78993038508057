import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Field, Form, Formik } from 'formik';
import { useUser } from '../../context/userContext';
import { useSnackbar } from 'notistack';
import SnackbarAction from '../common/SnackbarAction';
import { LinearProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Values {
  password: string;
  passwordConfirm: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPassword(): React.ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { resetPassword } = useUser();
  const query = useQuery();
  const history = useHistory();

  const decode = () => {
    if (query.get('data')) {
      try {
        return JSON.parse(decodeURIComponent(atob(query.get('data') || '')));
      } catch (error) {}
    }

    return {};
  };

  async function handleReset(values: Values) {
    try {
      if (resetPassword) {
        await resetPassword(decode()['username'], query.get('code') || '', values.password);
        enqueueSnackbar('Password reset successfully.', {
          variant: 'info',
          autoHideDuration: 2000,
          action: SnackbarAction,
        });
        history.push('/login');
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 5000, action: SnackbarAction });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (!values.password) {
              errors.password = 'Required';
            } else if (values.password.length < 8) {
              errors.password = 'Password should be at least 8 characters';
            } else if (values.password.length > 99) {
              errors.password = 'Password should not be greater than 99 characters';
            } else if (values.password.toLowerCase() === values.password) {
              errors.password = 'Password should have at least one uppercase';
            } else if (values.password.toUpperCase() === values.password) {
              errors.password = 'Password should have at least one lowercase';
            } else if (!/\d/.test(values.password)) {
              errors.password = 'Password should have at least one number';
            } else if (!/[!@#$%^&]/.test(values.password)) {
              errors.password = 'Password should have at least one special character. Ex: !@#$%^&';
            } else if (/\s/g.test(values.password)) {
              errors.password = 'Password should not have whitespace';
            }

            if (!values.passwordConfirm) {
              errors.passwordConfirm = 'Required';
            } else if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = 'Password should match';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            await handleReset(values);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.form} noValidate>
              <Field
                component={TextField}
                name="password"
                type="password"
                label="Enter your new password"
                fullWidth
                variant="outlined"
                margin="normal"
                autoFocus
              />

              <Field
                component={TextField}
                name="passwordConfirm"
                type="password"
                label="Enter your password one more time"
                fullWidth
                variant="outlined"
                margin="normal"
              />

              {isSubmitting && <LinearProgress />}
              <Button
                variant="contained"
                fullWidth
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                className={classes.submit}
                type="submit"
              >
                Reset password
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
