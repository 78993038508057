import gql from 'graphql-tag';

export const NEW_SESSION_SUBSCRIPTION = gql`
  subscription createdSession($email: AWSEmail!) {
    createdSession(buyerEmail: $email) {
      recordId
      record {
        seller {
          email
          givenName
          familyName
        }
        objective
      }
    }
  }
`;

export const SESSION_INVITE_RESPONSE_SUBSCRIPTION = gql`
  subscription respondedToSessionInvite($sellerEmail: AWSEmail!) {
    respondedToSessionInvite(sellerEmail: $sellerEmail) {
      accepted
    }
  }
`;

export const UPDATED_BUYER_SESSION = gql`
  subscription updatedBuyerSession($recordId: ID!) {
    updatedBuyerSession(recordId: $recordId) {
      recordId
      record {
        name
        company
        industry
        timeInPosition
        education
        location
        jobTile
      }
    }
  }
`;
