import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  children: React.ReactNode;
  title: string;
  className?: string;
};
export type Ref = HTMLDivElement;

const Page = forwardRef<Ref, Props>((props: Props, ref) => {
  return (
    <div ref={ref} className={props.className}>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {props.children}
    </div>
  );
});

Page.displayName = 'Page';

export default Page;
