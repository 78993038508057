import React from 'react';
import { Grid } from '@material-ui/core';

import ProfileDetails from './ProfileDetails';

const Profile = (): React.ReactElement => {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item lg={10} md={8} xl={12} xs={12}>
          <ProfileDetails className={'ta'} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;
