import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
import { theme } from '../../theme';

const useStyles = makeStyles(() => ({
  root: {},
  card: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
  },
}));

export interface Persona {
  name: string;
  jobTile: string;
  company: string;
  industry: string;
  timeInPosition: string;
  education: string;
  location: string;
}

interface HandleClickPersona {
  (selectedPersona: Persona): void;
}

export interface PersonaProps {
  persona: Persona;
  onClickHandler?: HandleClickPersona;
}

export function BuyerPersona(props: PersonaProps): React.ReactElement {
  const classes = useStyles();
  const { persona } = props;

  const handleOnClick = () => {
    if (props.onClickHandler) {
      props.onClickHandler(persona);
    }
  };

  return (
    <Grid item>
      <Card className={classes.root} elevation={2}>
        <CardActionArea onClick={handleOnClick}>
          <CardContent>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={5}>
              <Grid item>
                <Typography variant={'h4'}>{persona.name}</Typography>
              </Grid>
              <Grid item container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant={'h6'}>Job title:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'subtitle1'}>{persona.jobTile}</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant={'h6'}>Company:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'subtitle1'}>{persona.company}</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant={'h6'}>Industry:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'subtitle1'}>{persona.industry}</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant={'h6'}>Time in position:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'subtitle1'}>{persona.timeInPosition}</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant={'h6'}>Education:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'subtitle1'}>{persona.education}</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant={'h6'}>Location:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'subtitle1'}>{persona.location}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
