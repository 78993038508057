import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Auth } from '@aws-amplify/auth';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';

export interface LoginFunction {
  (username: string, password: string, rememberLogin: boolean): Promise<CognitoUser>;
}

export interface BeginPasswordFunction {
  (username: string): Promise<void>;
}

export interface ResetPasswordFunction {
  (username: string, code: string, newPassword: string): Promise<void>;
}

export interface LogoutFunction {
  (): Promise<void>;
}

export interface SignUpFunction {
  (
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    companyName: string,
    jobTitle: string,
    recaptcha: string,
    teamName: string,
    isInvite: string,
    inviteCode: string,
  ): Promise<ISignUpResult | null>;
}

export interface GetJwtTokenFunction {
  (): Promise<string>;
}

export interface UserAttributes {
  'custom:companyName': string;
  'custom:jobTitle': string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  sub: string;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface AuthState {
  user: CognitoUser | null;
  isLoaded: boolean;
  userAttributes?: Dictionary<string>;
}

export interface UserContext {
  user: AuthState;
  login?: LoginFunction;
  logout?: LogoutFunction;
  signUp?: SignUpFunction;
  getJwtToken?: GetJwtTokenFunction;
  beginPasswordReset?: BeginPasswordFunction;
  resetPassword?: ResetPasswordFunction;
}

export interface ProviderProps {
  children: React.ReactNode;
}

export const UserContext = React.createContext<UserContext>({ user: { user: null, isLoaded: false } });

export const UserProvider = (props: ProviderProps): React.ReactElement => {
  const [user, setUser] = useState<AuthState>({ user: null, isLoaded: false });

  useEffect(() => {
    Auth.configure({
      aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
      aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    });

    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        const temp = cognitoUser as CognitoUser;
        const userAttributes: Dictionary<string> = {};
        temp.getUserAttributes((error, userAttrs) => {
          if (!error) {
            userAttrs?.forEach((attr) => (userAttributes[attr.getName()] = attr.getValue()));
            setUser({ user: cognitoUser, isLoaded: true, userAttributes: userAttributes });
          } else {
            setUser({ user: cognitoUser, isLoaded: true });
          }
        });
      })
      .catch(() => {
        setUser({ user: null, isLoaded: true });
      });
  }, []);

  const login: LoginFunction = async (username: string, password: string, rememberLogin: boolean) => {
    try {
      rememberLogin ? Auth.configure({ storage: localStorage }) : Auth.configure({ storage: sessionStorage });

      const cognitoUser = await Auth.signIn(username, password);

      const userAttributes: Dictionary<string> = {};
      (cognitoUser as CognitoUser).getUserAttributes((error, userAttrs) => {
        userAttrs?.forEach((attr) => (userAttributes[attr.getName()] = attr.getValue()));
        setUser({ user: cognitoUser, isLoaded: true, userAttributes: userAttributes });
      });

      setUser({ user: cognitoUser, isLoaded: true });
      return cognitoUser;
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        error.message = 'Invalid username or password';
      }
      throw error;
    }
  };

  const logout: LogoutFunction = () =>
    Auth.signOut().then((data) => {
      setUser({ user: null, isLoaded: true });
      return data;
    });

  const signUp: SignUpFunction = async (
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    companyName: string,
    jobTitle: string,
    recaptcha: string,
    teamName: string,
    isInvite: string,
    inviteCode: string,
  ) => {
    try {
      const valData: {
        [key: string]: string;
      } = { recaptcha: recaptcha };

      const config = {
        username: username,
        password: password,
        attributes: {
          email: username,
          given_name: firstName,
          family_name: lastName,
          'custom:companyName': companyName,
          'custom:jobTitle': jobTitle,
          'custom:teamName': teamName,
          'custom:isInvited': isInvite,
        },
        validationData: valData,
      };

      if (isInvite !== 'false') {
        config.validationData.inviteCode = inviteCode;
      }

      return await Auth.signUp(config);
    } catch (error) {
      throw error;
    }
  };

  const getJwtToken: GetJwtTokenFunction = async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  };

  const beginPasswordReset: BeginPasswordFunction = async (email: string) => {
    return await Auth.forgotPassword(email);
  };

  const resetPassword: ResetPasswordFunction = async (username: string, code: string, newPassword: string) => {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
  };

  const values = useMemo(() => ({ user, login, logout, signUp, getJwtToken, beginPasswordReset, resetPassword }), [
    user,
  ]);

  return <UserContext.Provider value={values}>{props.children}</UserContext.Provider>;
};

export const useUser = (): UserContext => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('`useUser` must be within a `UserProvider`');
  }
  return context;
};
