import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

interface Timer {
  minutes: string;
  seconds: string;
}

interface HandlerFunction {
  (): void;
}

export interface TimerProperties {
  isCountDown: boolean;
  countDownSeconds: number;
  label: string;
  handleCountDown?: HandlerFunction;
}

const getTimeData = (timeInSeconds: number) => {
  let seconds = (timeInSeconds % 60).toString(10);
  seconds = seconds.length === 1 ? seconds.padStart(2, '0') : seconds;
  return {
    minutes: Math.floor(timeInSeconds / 60).toString(10),
    seconds: seconds,
  };
};

export default function Timer({
  isCountDown,
  label,
  countDownSeconds,
  handleCountDown,
}: TimerProperties): React.ReactElement {
  const [timeLeft, setTimeLeft] = useState<number>(countDownSeconds);
  const [timer, setTimer] = useState<Timer>(getTimeData(countDownSeconds));

  useEffect(() => {
    function countDown() {
      const difference = timeLeft - 1;
      if (difference > 0) {
        setTimeLeft(timeLeft - 1);
        setTimer(getTimeData(difference));
      } else {
        if (timer.minutes === '0' && timer.seconds === '00') {
          return;
        }
        setTimer(getTimeData(0));
        handleCountDown!();
      }
    }

    function countUp() {
      setTimeLeft(timeLeft + 1);
      setTimer(getTimeData(timeLeft + 1));
    }

    const timeout = setTimeout(() => (isCountDown ? countDown() : countUp()), 1000);
    return () => clearTimeout(timeout);
  }, [timeLeft, handleCountDown, timer, isCountDown]);

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {timer.minutes}:{timer.seconds}
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        {label}
      </Typography>
    </>
  );
}
