import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { BuyerPersona, Persona } from './Persona';
import { GET_SESSION } from '../../graphql/queries';
import { useUser } from '../../context/userContext';
import { useHistory, useParams } from 'react-router-dom';
import { CREATE_SESSION_TOKEN } from '../../graphql/mutations';
import ChoosePersona from './ChoosePersona';
import Timer from '../common/Timer';
import Room from './Room';
import Notes from './SellerNotes';
import { UPDATED_BUYER_SESSION } from '../../graphql/subscriptions';
import { mutation, useQuery, useSubscription } from '../../graphql/client';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface Timer {
  minutes: string;
  seconds: string;
}

export interface RouteParams {
  id: string;
}

interface User {
  email: string;
}

interface Session {
  id: string;
  createdAt: number;
  leadType: string;
  seller: User;
  buyer: User;
  objective: string;
  status: string;
  buyerPersona: Persona;
  sellerNotes: string;
}

interface SessionPayload {
  getSession: Session;
}

export interface SessionToken {
  recordId: string;
}

export interface SessionTokenPayload {
  createSessionToken: SessionToken;
}

interface UpdatedSessionPersona {
  recordId: string;
  record: Persona;
}

interface UpdatedBuyerSessionPayload {
  updatedBuyerSession: UpdatedSessionPersona;
}

export default function Session(): React.ReactElement {
  const history = useHistory();
  const [ready, setReady] = useState<boolean>(true);
  const [persona, setPersona] = useState<Persona | null>(null);
  const [startCall, setStartCall] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [token, setToken] = useState('');
  const { user } = useUser();
  const { id } = useParams<RouteParams>();

  const { loading, data } = useQuery<SessionPayload>(GET_SESSION, { id });

  useDeepCompareEffect(() => {
    if (data && data.getSession && data.getSession.buyerPersona) {
      setPersona(data.getSession.buyerPersona);
    }
  }, [data]);

  const [item] = useSubscription<UpdatedSessionPersona>({
    config: {
      query: UPDATED_BUYER_SESSION,
      variables: { recordId: id },
      key: 'updatedBuyerSession',
    },
  });

  useEffect(() => {
    if (item) {
      setPersona(item.record);
    }
  }, [item]);

  useEffect(() => {
    const handleNavigateAway = (event: BeforeUnloadEvent) => {
      if (!ready) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleNavigateAway);

    return () => {
      window.removeEventListener('beforeunload', handleNavigateAway);
    };
  }, [data, ready]);

  const handleBeginCall = () => {
    setStartTimer(true);
  };

  const handleEndSession = () => {
    history.push(`/survey/${id}`);
  };

  const handlePersonaSelected = (selectedPersona: Persona) => {
    setPersona(selectedPersona);
  };

  const handleTimerDone = async () => {
    setReady(false);
    const result = await mutation<SessionTokenPayload>(CREATE_SESSION_TOKEN);
    if (result.createSessionToken) {
      setToken(result.createSessionToken.recordId);
      setStartCall(true);
    }
  };

  return (
    <Container component="main" maxWidth="xl">
      {!!!data?.getSession?.buyerPersona && data?.getSession?.buyer.email === user.userAttributes?.email && (
        <ChoosePersona handlePersonaSelect={handlePersonaSelected} sessionId={id} />
      )}

      <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
        {!loading ? (
          <>
            <Grid item container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Typography>
                  Purpose of the call: <b>{data?.getSession.objective}</b>
                </Typography>
              </Grid>
              <Grid item>
                {startTimer ? (
                  <Timer countDownSeconds={0} isCountDown={false} label="Time in call" />
                ) : (
                  <Timer
                    countDownSeconds={10}
                    handleCountDown={handleTimerDone}
                    isCountDown={true}
                    label="Time before call"
                  />
                )}
              </Grid>
            </Grid>

            <Grid item container direction="row" justify="center" alignItems="center" spacing={2}>
              <Grid item>
                {!!persona ? (
                  <BuyerPersona persona={persona} />
                ) : (
                  <>
                    <CircularProgress size={24} />
                    <Typography>Waiting for Customer to select a persona</Typography>
                  </>
                )}
              </Grid>

              {data?.getSession.seller.email === user.userAttributes?.email && (
                <Grid item>
                  <Notes sessionId={id} initialNotes={data?.getSession.sellerNotes} />
                </Grid>
              )}
            </Grid>
            <Grid item>
              {startCall && (
                <Room
                  token={token}
                  sessionId={id}
                  userId={user.userAttributes ? user.userAttributes.email : ''}
                  handleStart={handleBeginCall}
                  handleEnd={handleEndSession}
                />
              )}
            </Grid>
          </>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Container>
  );
}
