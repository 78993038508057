import React from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../common/Page';
import Results from './Results';

const Team = () => {
  return (
    <Page title="Team">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Results />
        </Box>
      </Container>
    </Page>
  );
};

export default Team;
