import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { NoParamsVoidFunction } from '../../common/types';

export interface CallButtonProps {
  handleClick: NoParamsVoidFunction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

export default function CallButton(props: CallButtonProps): React.ReactElement {
  const classes = useStyles();
  const [inProgress, setInProgress] = useState(false);
  const handleClick = () => {
    setInProgress(!inProgress);
    props.handleClick();
  };
  return (
    <Button
      variant="contained"
      color={inProgress ? 'secondary' : 'primary'}
      className={classes.margin}
      startIcon={inProgress ? <CallEndIcon /> : <CallIcon />}
      onClick={handleClick}
    >
      {inProgress ? 'End Call' : 'Start Call'}
    </Button>
  );
}
