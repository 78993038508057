import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  GridList,
  GridListTile,
  IconButton,
  Theme,
  Typography,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LinearProgress,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { LIST_USERS } from '../../graphql/queries';
import { User } from '../../common/types';
import { useQuery } from '../../graphql/client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: '100%',
      height: 350,
    },
    inline: {},
  }),
);

export interface UsersQueryResult {
  getUsers: UsersPage;
}

export interface UsersPage {
  users: User[];
  nextToken: string;
}

const TeamPerformanceList = (): React.ReactElement => {
  const classes = useStyles();
  const { loading, data, refetch } = useQuery<UsersQueryResult>(LIST_USERS);

  const handleRefresh = async () => {
    refetch();
  };

  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        }
        title="Team"
      />
      <Divider />
      <CardContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <div className={classes.root}>
            <GridList cellHeight={80} className={classes.gridList} cols={3}>
              {data && data.getUsers ? (
                data.getUsers.users.map((u) => (
                  <GridListTile key={u.email} cols={1}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt={`${u.givenName} ${u.familyName}`} src={u.profilePicUrl} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${u.givenName} ${u.familyName}`}
                        secondary={
                          <React.Fragment>
                            {'Score — '}
                            <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                              {u.averageScore ? u.averageScore.toFixed(1) : 0.0}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </GridListTile>
                ))
              ) : (
                <p>No Users</p>
              )}
            </GridList>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default TeamPerformanceList;
