import React from 'react';
import { Box, Container } from '@material-ui/core';
import Page from '../common/Page';
import ChangePassword from './ChangePassword';

const SettingsView = () => {
  return (
    <Page title="Settings">
      <Container maxWidth="lg">
        <Box mt={3}>
          <ChangePassword />
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
