import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IconButton, Card, CardContent, CardHeader, Divider, LinearProgress } from '@material-ui/core';
import { LIST_TEAM_SESSIONS } from '../../graphql/queries';
import { User } from '../../common/types';
import LeadEmoji from '../common/LeadEmoji';
import { secondsToString, formatDate } from '../../common/utils';
import SmallMediaPlayer from '../common/SmallMediaPlayer';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useQuery } from '../../graphql/client';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface Session {
  id: string;
  teamName: string;
  score: number;
  objective: string;
  leadType: string;
  buyer: User;
  seller: User;
  recordingDuration: number;
  recordingUrl: string;
  createdAt: number;
  status: string;
}

export interface TeamSessionsQueryResult {
  listTeamSessions: SessionConnection;
}

interface SessionConnection {
  items: Session[];
  nextToken: string;
}

export default function TeamRecordingsTable(): React.ReactElement {
  const classes = useStyles();
  const { loading, data, refetch } = useQuery<TeamSessionsQueryResult>(LIST_TEAM_SESSIONS, {
    limit: 10,
  });

  const handleRefresh = async () => {
    refetch();
  };

  return (
    <Card>
      <CardHeader
        title="Recordings"
        action={
          <IconButton aria-label="settings" onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SELLER</TableCell>
                  <TableCell align="center">SCORE</TableCell>
                  <TableCell align="center">CALL PURPOSE</TableCell>
                  <TableCell align="center">LEAD TYPE</TableCell>
                  <TableCell align="center">CUSTOMER</TableCell>
                  <TableCell align="center">CALL DURATION</TableCell>
                  <TableCell align="center">DATE</TableCell>
                  <TableCell align="center">LISTEN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.listTeamSessions ? (
                  data.listTeamSessions.items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell align="center">{`${item.seller.givenName} ${item.seller.familyName}`}</TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {item.score ? item.score.toFixed(1) : 'No Score'}
                      </TableCell>
                      <TableCell align="center">{item.objective}</TableCell>
                      <TableCell align="center">
                        <LeadEmoji type={item.leadType} />
                      </TableCell>
                      <TableCell align="center">{`${item.buyer.givenName} ${item.buyer.familyName}`}</TableCell>
                      <TableCell align="center">{secondsToString(item.recordingDuration)}</TableCell>
                      <TableCell align="center">{formatDate(item.createdAt)}</TableCell>
                      <TableCell align="center">
                        <SmallMediaPlayer url={item.recordingUrl} duration={item.recordingDuration} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <p>No recordings yet</p>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
}
