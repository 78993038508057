import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Field, Form, Formik } from 'formik';
import { useUser } from '../../context/userContext';
import { useSnackbar } from 'notistack';
import SnackbarAction from '../common/SnackbarAction';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Values {
  email: string;
}

export default function ForgotPassword() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { beginPasswordReset } = useUser();

  async function handleReset(values: Values) {
    try {
      if (beginPasswordReset) {
        await beginPasswordReset(values.email);
        enqueueSnackbar('Check your inbox.', { variant: 'info', autoHideDuration: 5000, action: SnackbarAction });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 5000, action: SnackbarAction });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Formik
          initialValues={{
            email: '',
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            await handleReset(values);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.form} noValidate>
              <Field
                component={TextField}
                name="email"
                type="email"
                label="Email Address"
                fullWidth
                variant="outlined"
                margin="normal"
                autoFocus
              />

              {isSubmitting && <LinearProgress />}
              <Button
                variant="contained"
                fullWidth
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                className={classes.submit}
                type="submit"
              >
                Reset
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
