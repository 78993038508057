import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Rating from './QuestionRating';
import { Card, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { UPDATE_SESSION_SURVEY } from '../../graphql/mutations';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { RouteParams } from '../call/Session';
import LoadingBackdrop from '../common/LoadingBackdrop';
import { mutation } from '../../graphql/client';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#1B31EA',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1B31EA',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 6,
    borderRadius: 3,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#1B31EA',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#1B31EA',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  // const { active, completed } = props;
  const { active } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {/* {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />} */}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

function getQuestions() {
  return [
    {
      name: 'rapport',
      description: 'Rapport Building',
      score: 0.0,
    },
    {
      name: 'messaging',
      description: 'Messaging',
      score: 0.0,
    },
    {
      name: 'solutionKnowledge',
      description: 'Solution Knowledge',
      score: 0.0,
    },
    {
      name: 'vocalClarity',
      description: 'Vocal Clarity',
      score: 0.0,
    },
    {
      name: 'authenticity',
      description: 'Authenticity',
      score: 0.0,
    },
    {
      name: 'preparedness',
      description: 'Preparedness',
      score: 0.0,
    },
    {
      name: 'enthusiasm',
      description: 'Enthusiasm',
      score: 0.0,
    },
    {
      name: 'pacing',
      description: 'Pacing',
      score: 0.0,
    },
    {
      name: 'closing',
      description: 'Closing',
      score: 0.0,
    },
  ];
}

export interface Question {
  name: string;
  description: string;
  score: number;
}

export default function Survey(): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [questions, SetQuestions] = useState<Array<Question>>(getQuestions());
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    if (questions[activeStep].score === 0) {
      enqueueSnackbar('Select a valid rating to continue.', { variant: 'warning' });
      return;
    }

    if (activeStep === questions.length - 1) {
      await handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getScores = () => {
    const scores: Record<string, number> = {};
    questions.forEach((q) => (scores[q.name] = q.score));
    return scores;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await mutation(UPDATE_SESSION_SURVEY, {
        sessionId: id,
        surveyDetails: getScores(),
      });
    } catch (error) {
      enqueueSnackbar('Failed to submit survey.', { variant: 'error' });
      console.error(error);
    }

    setLoading(false);
    history.push('/home');
  };

  const handleScoreSelection = (score: number, index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].score = score;
    SetQuestions(updatedQuestions);
  };

  function getSurveyQuestion(step: number) {
    return <Rating handleSelectRating={handleScoreSelection} question={questions[step]} index={step} />;
  }

  return (
    <>
      <Card>
        <CardHeader title="Survey" />
        <Divider />
        <CardContent>
          <div className={classes.root}>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
              <Grid item xs={12}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                  {questions.map((question) => (
                    <Step key={question.name}>
                      <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" spacing={5}>
              {activeStep !== questions.length && (
                <>
                  <Grid item>
                    <Typography variant={'h2'}>{questions[activeStep].description}</Typography>
                  </Grid>
                  <Grid item>{getSurveyQuestion(activeStep)}</Grid>
                </>
              )}

              <Grid item>
                {activeStep !== questions.length && (
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                      {activeStep === questions.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
      <LoadingBackdrop message="Submitting Survey..." open={loading} />
    </>
  );
}
