import { Container, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import Page from '../common/Page';
import TeamPerformance from './TeamPerformance';
import TeamPerformanceList from './TeamPerformanceList';
import TeamRecordingsTable from './TeamRecordingsTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

export default function TeamDashboard(): React.ReactElement {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Team Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <TeamPerformance />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <TeamPerformanceList />
          </Grid>
          <Grid item>
            <TeamRecordingsTable />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
