import React, { useEffect, useState, useCallback } from 'react';
import { Connection, Device } from 'twilio-client';
import { useSnackbar } from 'notistack';
import CallAudioControls from '../common/CallAudioControls';
import { NoParamsVoidFunction } from '../../common/types';

interface RoomProperties {
  sessionId: string;
  userId: string;
  token: string;
  handleStart: NoParamsVoidFunction;
  handleEnd: NoParamsVoidFunction;
}

export interface AudioDevice {
  label: string;
  deviceId: string;
  isActive: boolean;
}

export interface AudioDeviceParamFunction {
  (audioDev: AudioDevice): Promise<void>;
}

export enum DeviceState {
  CONNECTING = 'Connecting',
  READY = 'Ready',
  INCOMING = 'Incoming',
  ON_CALL = 'On call',
  OFFLINE = 'Offline',
  DISCONNECTED = 'Disconnected',
}

export default function Room({ sessionId, userId, token, handleStart, handleEnd }: RoomProperties): React.ReactElement {
  const [state, setState] = useState(DeviceState.CONNECTING);
  const [device, setDevice] = useState<Device>(() => new Device());
  const [connection, setConnection] = useState<Connection | null>(null);
  const [inputAudioDevice, setInputAudioDevice] = useState<Array<AudioDevice>>([]);
  const [activeMic, setActiveMic] = useState<AudioDevice>({ label: 'none', deviceId: 'none', isActive: false });
  const [outputAudioDevice, setOutputAudioDevice] = useState<Array<AudioDevice>>([]);
  const [activeSpeaker, setActiveSpeaker] = useState<AudioDevice>({ label: 'none', deviceId: 'none', isActive: false });

  const { enqueueSnackbar } = useSnackbar();

  const updateMicOptions = useCallback(
    (device: Device) => {
      if (device && device.audio) {
        const micDev: Array<AudioDevice> = [];
        device.audio?.availableInputDevices.forEach((d) => {
          const audioDev = { deviceId: d.deviceId, label: d.label, isActive: false };
          if (activeMic.deviceId !== 'none') {
            if (activeMic.deviceId === d.deviceId) {
              audioDev.isActive = true;
            }
          } else {
            if (d.deviceId === 'default') {
              audioDev.isActive = true;
              setActiveMic(audioDev);
            }
          }
          micDev.push(audioDev);
        });

        setInputAudioDevice(micDev);
      }
    },
    [activeMic.deviceId],
  );

  const updateSpeakerOptions = useCallback(
    (device: Device) => {
      if (device && device.audio) {
        const speakerDev: Array<AudioDevice> = [];
        device.audio?.availableOutputDevices.forEach((d) => {
          const audioDev = { deviceId: d.deviceId, label: d.label, isActive: false };
          if (activeSpeaker.deviceId !== 'none') {
            if (activeSpeaker.deviceId === d.deviceId) {
              audioDev.isActive = true;
            }
          } else {
            if (d.deviceId === 'default') {
              audioDev.isActive = true;
              setActiveSpeaker(audioDev);
            }
          }
          speakerDev.push(audioDev);
        });

        setOutputAudioDevice(speakerDev);
      }
    },
    [activeSpeaker.deviceId],
  );

  const setMicOption: AudioDeviceParamFunction = async (audioDev: AudioDevice) => {
    try {
      await device.audio?.setInputDevice(audioDev.deviceId);
      setActiveMic(audioDev);
    } catch (error) {
      console.log(error);
    }
  };

  const setSpeakerOption: AudioDeviceParamFunction = async (audioDev: AudioDevice) => {
    try {
      await device.audio?.speakerDevices.set(audioDev.deviceId);
      setActiveSpeaker({ ...audioDev, isActive: true });
      updateSpeakerOptions(device);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateMicOptions(device);
    updateSpeakerOptions(device);
  }, [activeSpeaker, activeMic, device, updateMicOptions, updateSpeakerOptions]);

  useEffect(() => {
    device.setup(token, { codecPreferences: [Connection.Codec.Opus, Connection.Codec.PCMU] });

    device.on('ready', () => {
      setDevice(device);
      setState(DeviceState.READY);
      enqueueSnackbar('Ready for call.', { variant: 'success' });

      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          updateMicOptions(device);
          updateSpeakerOptions(device);
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((error) => {
          // Handle error. Tell the user there's a a mic issue. You could also tell
          // your backend or raise an alert for the system admin to resolve this issue.
          console.log(error);
        });

      device.audio?.on('deviceChange', () => {
        updateMicOptions(device);
        updateSpeakerOptions(device);
      });
    });

    device.on('connect', (connection) => {
      enqueueSnackbar('Connected to the session.', { variant: 'info' });
      setConnection(connection);
      setState(DeviceState.ON_CALL);
    });
    device.on('disconnect', () => {
      setState(DeviceState.DISCONNECTED);
      setConnection(null);
      handleEnd();
    });
    device.on('incoming', (connection) => {
      setState(DeviceState.INCOMING);
      setConnection(connection);
      connection.on('reject', () => {
        setState(DeviceState.READY);
        setConnection(null);
      });
      connection.on('warning', (warningName: string) => {
        if (warningName === 'low-mos') {
          enqueueSnackbar('Poor call quality conditions detected.', {
            variant: 'warning',
          });
        }
      });
      connection.on('warning-cleared', (warningName: string) => {
        if (warningName === 'low-mos') {
          enqueueSnackbar('Poor call quality conditions cleared.', {
            variant: 'success',
          });
        }
      });
    });
    device.on('cancel', () => {
      setState(DeviceState.READY);
      setConnection(null);
    });
    device.on('reject', () => {
      setState(DeviceState.READY);
      setConnection(null);
    });
    device.on('error', (error) => {
      setState(DeviceState.READY);
      setConnection(null);
      enqueueSnackbar(`Failed to connect to call. Error: ${error.code}`, { variant: 'error' });
      console.error(error.message);
    });

    return () => {
      device.disconnectAll();
      device.destroy();
    };
    // eslint-disable-next-line
  }, [token, enqueueSnackbar]);

  const handleCall = () => {
    if (state === DeviceState.READY) {
      try {
        device?.connect({
          userId: userId,
          sessionId: sessionId,
        });
        handleStart();
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to connect to call.', { variant: 'error' });
      }
    } else if (state === DeviceState.ON_CALL) {
      device?.disconnectAll();
      enqueueSnackbar('Session ended.', { variant: 'info' });
      handleEnd();
    }
  };

  const handleMute = (value: boolean) => {
    if (connection) {
      connection.mute(value);
    } else {
      enqueueSnackbar('Could not mute device. Connection invalid.', { variant: 'warning' });
    }
  };

  return (
    <CallAudioControls
      deviceState={state}
      handleCall={handleCall}
      handleMute={handleMute}
      handleSelectMic={setMicOption}
      handleSelectSpeaker={setSpeakerOption}
      isMuted={false}
      inputDevices={inputAudioDevice}
      outputDevices={outputAudioDevice}
    />
  );
}
