import { Backdrop, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export interface LoadingBackdropProps {
  message: string;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function LoadingBackdrop(props: LoadingBackdropProps): React.ReactElement {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <CircularProgress color="inherit" />
        </Grid>
        <Grid item>
          <Typography>{props.message}</Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
}
